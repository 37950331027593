import "@teliads/components/dist/esm/polyfills/dom";
import { applyPolyfills } from "@teliads/components/loader";
import { defineCustomElements } from "@teliads/components/dist/custom-elements";
import { registerIconsBundle } from "@teliads/icons";

// single-spa exports
export async function bootstrap(): Promise<void> {
  await applyPolyfills();

  registerIconsBundle();
  defineCustomElements();
}

export async function mount(): Promise<void> {
  return;
}

export async function unmount(): Promise<void> {
  return;
}
